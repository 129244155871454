import { APITypesV1 } from "@cur8/api-client";
import { PadAssessmentBox, Patient } from "@cur8/rich-entity";
import { useEffect, useState } from "react";
import { useImmutableScan } from "render/hooks/api/useImmutableScan";
import { usePatient } from "render/hooks/api/usePatient";
import { useAssessmentNav } from "../../hooks/useAssessmentNav";
import ImageViewer from "./components/ImageViewer";
import ImageToggles from "./components/ImageViewer/ImageToggles";
import ProcessErrors from "./components/ProcessErrors";
import Sidebox from "./components/Sidebox";
import TissueImageContext from "./context/TissueImageContext";
import { useCameraCalibration } from "./hooks/useCameraCalibration";
import { useProcessedMetadata } from "./hooks/useProcessedMetadata";
import { PropertyRange, TissueContextMode } from "./lib/types";
import { rangeParser } from "./lib/utils";
import styles from "./styles.module.sass";

interface PadPageProps {
  patient: Patient;
  assessment: PadAssessmentBox;
}

export default function PadPage({ patient, assessment }: PadPageProps) {
  const scan = useImmutableScan(
    patient.patientId,
    assessment.scanId,
    assessment.scanVersion
  );

  const patientRequest = usePatient(patient.patientId);
  const { processedMetadata, metadataError } = useProcessedMetadata(scan);
  const { property, roi, setProperty, setSelectedRoi } = useAssessmentNav();

  const [indexRemap, setIndexRemap] = useState<number[]>();
  const [timestamps, setTimestamps] = useState<number[]>([]);
  const [range, setRange] = useState<PropertyRange | undefined>();
  const [hasBloodVesselsMask, setHasBloodVesselsMask] =
    useState<boolean>(false);

  const [pixelsPerCm, setPixelsPerCm] = useState<number>();
  const cameraCalibration = useCameraCalibration(scan);
  useEffect(() => {
    if (!cameraCalibration) {
      return;
    }
    setPixelsPerCm(cameraCalibration.camera.pix_per_cm);
  }, [cameraCalibration]);

  useEffect(() => {
    if (!processedMetadata) {
      return;
    }
    if (processedMetadata.blood_vessels_mask !== undefined) {
      setHasBloodVesselsMask(processedMetadata.blood_vessels_mask);
    }
  }, [processedMetadata]);

  useEffect(() => {
    if (!processedMetadata) {
      return;
    }
    try {
      const r = rangeParser(
        processedMetadata.ranges[property],
        processedMetadata.version
      );
      setRange(r);
    } catch (err) {
      console.debug(
        "Failed to parse ranges",
        err,
        processedMetadata.ranges,
        property
      );
    }
  }, [processedMetadata, property]);

  useEffect(() => {
    if (!scan) {
      return;
    }

    const aPrefix = "acq_";
    const acqs = Object.entries(scan.resultStateSummary)
      .filter(
        ([k, v]) =>
          k.startsWith(aPrefix) && v === APITypesV1.ResultState.Complete
      )
      .map(([k, _]) => parseInt(k.slice(aPrefix.length)))
      .sort((a, b) => a - b);
    setIndexRemap(acqs);

    if (!processedMetadata) {
      return;
    }
    // The old processing pipeline contained only the processed timestamps
    // Now that we do incremental processing we get all timestamps and need
    // to filter out the ones that we don't need.
    const ts =
      (processedMetadata.version ?? 1) > 1
        ? acqs.map((i) => processedMetadata.timestamp_s[i])
        : processedMetadata.timestamp_s;

    setTimestamps(ts);
  }, [scan, processedMetadata]);

  if (scan && metadataError && !processedMetadata) {
    return <h1>{metadataError}</h1>;
  }
  if (!patientRequest || !scan || !processedMetadata) {
    return <h1>Fetching data...</h1>;
  }

  return (
    <div className={styles.PadPage}>
      <TissueImageContext
        scan={scan}
        property={property}
        index={0}
        maxIndex={0}
        mode={TissueContextMode.PAD}
        version={processedMetadata?.version || 1}
        hasBloodVesselsMask={hasBloodVesselsMask}
      >
        <section className={styles.top}>
          {scan && timestamps && indexRemap && processedMetadata ? (
            <Sidebox
              indexRemap={indexRemap}
              onPropertySelect={setProperty}
              onRoiSelect={setSelectedRoi}
              pixelsPerCm={pixelsPerCm}
              scan={scan}
              selectedRoi={roi}
              property={property}
              timestamps={timestamps}
            />
          ) : (
            <h3 className={styles.loading}>Loading ratios</h3>
          )}
          {range && assessment.regionsOfInterest && (
            <ImageViewer
              rois={assessment.regionsOfInterest}
              range={range}
              selectedRoi={roi}
            >
              <div className={styles.imgTooglePos}>
                <ImageToggles autoflow="column" isPAD={true} />
              </div>
            </ImageViewer>
          )}
        </section>
      </TissueImageContext>
      <ProcessErrors processErrors={processedMetadata.errors} />
    </div>
  );
}
