import { APITypesV1 } from "@cur8/api-client";
import {
  Assessment,
  AssessmentTypeName,
  Box,
  isPadAssessment,
  isPulseWaveAssessment,
  Patient,
  RegionsOfInterestBox,
} from "@cur8/rich-entity";
import { toRect } from "lib/api/adapter/annotation";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAssessmentNav } from "../../hooks/useAssessmentNav";

interface AssessmentProps {
  patient: Patient;
  assessments: Assessment[];
  selected: Assessment;
}

export type CreatePadAssessmentRequestBox = Exclude<
  APITypesV1.CreatePadAssessmentRequest,
  APITypesV1.RegionsOfInterest
> & {
  regionsOfInterest?: RegionsOfInterestBox;
};

export type PulseWaveData = Pick<
  APITypesV1.CreatePulseWaveAssessmentRequest,
  "lveti" | "pulseWaveVelocity" | "handAsymmetry" | "footAsymmetry"
>;
export type PadData = Pick<
  APITypesV1.CreatePadAssessmentRequest,
  "ratios" | "regionsOfInterest"
>;

function initCreateAssessment(
  ass: Assessment
): CreatePadAssessmentRequestBox | APITypesV1.CreatePulseWaveAssessmentRequest {
  if (isPadAssessment(ass)) {
    return {
      assessmentState: ass.assessmentState,
      regionsOfInterest: ass.regionsOfInterest,
      ratios: ass.ratios,
    } as CreatePadAssessmentRequestBox;
  } else if (isPulseWaveAssessment(ass)) {
    return {
      assessmentState: ass.assessmentState,
      lveti: ass.lveti,
      footAsymmetry: ass.footAsymmetry,
      handAsymmetry: ass.handAsymmetry,
      pulseWaveVelocity: ass.pulseWaveVelocity,
    } as APITypesV1.CreatePulseWaveAssessmentRequest;
  } else {
    throw new Error("Invalid assessment");
  }
}

export default function useAssessment({
  patient,
  assessments,
  selected,
}: AssessmentProps) {
  const api = useAPIClient().assessment;
  const { setVersionId } = useAssessmentNav();
  const [isModified, setModified] = useState<boolean>(false);
  const [createAssessment, setCreateAssessment] = useState<
    CreatePadAssessmentRequestBox | APITypesV1.CreatePulseWaveAssessmentRequest
  >(() => initCreateAssessment(selected));

  useEffect(() => {
    if (selected) {
      setCreateAssessment(() => initCreateAssessment(selected));
    }
  }, [selected]);

  const save = useCallback(() => {
    if (!assessments) {
      throw new Error("No assessment found");
    }
    if (!selected.isLatestVersion) {
      throw new Error("Not viewing latest, not allowed to save assessment");
    }
    if (!isModified) {
      console.debug("Not modified, skip save");
      return;
    }

    let res;
    if (isPadAssessment(selected)) {
      const ca = createAssessment as CreatePadAssessmentRequestBox;
      console.debug("ratios:", ca.ratios);
      res = api.createPadAssessments(
        {
          patientId: patient.patientId,
          assessmentId: selected.itemId,
        },
        {
          assessmentState: ca.assessmentState,
          comment: ca.comment,
          ratios: ca.ratios,
          regionsOfInterest: {
            forearm: toRect(ca.regionsOfInterest!.forearm),
            hypothenar: toRect(ca.regionsOfInterest!.hypothenar),
          },
        }
      ).result;
    } else if (isPulseWaveAssessment(selected)) {
      res = api.createPulseWaveAssessments(
        {
          patientId: patient.patientId,
          assessmentId: selected.itemId,
        },
        createAssessment
      ).result;
    } else {
      throw new Error("Unsupported assessment");
    }

    res.then((ass) => {
      console.debug("Created assessment", ass);
      try {
        setVersionId(ass.itemVersion);
        setModified(false);
      } catch (err) {
        console.error(err);
      }
    });
  }, [
    api,
    assessments,
    createAssessment,
    isModified,
    patient.patientId,
    selected,
    setVersionId,
  ]);

  const loadLatest = useCallback(() => {
    const latest = assessments[0];
    try {
      setVersionId(latest.itemVersion);
      setModified(false);
    } catch (err) {
      console.error(err);
    }
  }, [assessments, setVersionId]);

  const setComment = useCallback((comment: string) => {
    setCreateAssessment((ass) => ({
      ...ass,
      comment: comment,
    }));
    setModified(true);
  }, []);

  const setPadRatio = useCallback(
    (
      key: keyof APITypesV1.PadAssessmentRatios,
      val: APITypesV1.PadAssessmentRatios[keyof APITypesV1.PadAssessmentRatios]
    ) => {
      setCreateAssessment((ass: APITypesV1.CreatePadAssessmentRequest) => ({
        ...ass,
        ratios: {
          ...ass.ratios,
          [key]: val,
        },
      }));
    },
    []
  );

  const setPulseWaveData = useCallback(
    (key: keyof PulseWaveData, val: PulseWaveData[keyof PulseWaveData]) => {
      setCreateAssessment(
        (ass: APITypesV1.CreatePulseWaveAssessmentRequest) => ({
          ...ass,
          [key]: val,
        })
      );
      //setModified(true);
    },
    []
  );

  const setState = useCallback((state: APITypesV1.AssessmentState) => {
    setCreateAssessment((ass) => ({
      ...ass,
      assessmentState: state,
    }));
    setModified(true);
  }, []);

  const isSavable = useCallback((): boolean => {
    if (!selected.isLatestVersion) {
      return false;
    }
    if (createAssessment.assessmentState === APITypesV1.AssessmentState.New) {
      return false;
    }
    if (!isModified) {
      return false;
    }
    if (isPadAssessment(selected)) {
      const ca = createAssessment as APITypesV1.CreatePadAssessmentRequest;
      if (ca.ratios && ca.regionsOfInterest) {
        return true;
      }
    } else if (isPulseWaveAssessment(selected)) {
      const ca =
        createAssessment as APITypesV1.CreatePulseWaveAssessmentRequest;
      if (
        ca.footAsymmetry &&
        ca.handAsymmetry &&
        ca.lveti &&
        ca.pulseWaveVelocity
      ) {
        return true;
      }
    }
    return false;
  }, [createAssessment, isModified, selected]);

  /**
   * PAD-only
   * Move a Region of Interest
   */
  const moveRoI = useCallback(
    (id: string, box: Box) => {
      if (selected.itemTypeName !== AssessmentTypeName.Pad) {
        console.debug("Wrong type");
        return;
      }
      setCreateAssessment((ass: APITypesV1.CreatePadAssessmentRequest) => ({
        ...ass,
        regionsOfInterest: {
          ...ass.regionsOfInterest,
          [id]: box,
        },
      }));
      setModified(true);
    },
    [selected.itemTypeName]
  );

  return {
    assessments,
    createAssessment,
    fetch,
    isModified,
    isSavable,
    loadLatest,
    moveRoI,
    save,
    selected,
    setComment,
    setPadRatio,
    setPulseWaveData,
    setState,
  };
}

export type AssessmentContextValue = ReturnType<typeof useAssessment>;
