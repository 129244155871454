import { APITypesV1 } from "@cur8/api-client";
import {
  Assessment,
  isPadAssessment,
  isPulseWaveAssessment,
} from "@cur8/rich-entity";
import { useAssessmentContext } from "../../context/AssessmentContext";
import { useAssessmentNav } from "../../hooks/useAssessmentNav";
import AssessedBy from "./AssessedBy";
import styles from "./styles.module.sass";

interface HistoryListProps {
  assessments: Assessment[];
}

function readableState(state: APITypesV1.AssessmentState): string {
  switch (state) {
    case APITypesV1.AssessmentState.Cleared:
      return "Cleared";
    case APITypesV1.AssessmentState.FollowUpRequested:
      return "Follow up";
    case APITypesV1.AssessmentState.ReviewRequested:
      return "Review requested";
    case APITypesV1.AssessmentState.New:
    default:
      return "Unassessed";
  }
}

export default function HistoryList({ assessments }: HistoryListProps) {
  const { selected } = useAssessmentContext();
  const { setVersionId } = useAssessmentNav();

  return (
    <div className={styles.HistoryList}>
      <h3>All assessments</h3>
      <div className={styles.scrollbox}>
        {assessments.map((ass) => (
          <div key={ass.itemVersion}>
            <div className={styles.topbar}>
              <div className={styles.byline}>
                <label>Created</label>
                <span>{ass.createdAt.toFormat("yyyy-LL-dd HH:mm")}</span>
              </div>
              {ass.itemVersion !== selected.itemVersion && (
                <button onClick={() => setVersionId(ass.itemVersion)}>
                  View version
                </button>
              )}
            </div>
            <div className={styles.version} data-type={ass.itemTypeName}>
              {isPadAssessment(ass) && (
                <div className={styles.data}>
                  <label>Shallow Blood Concentration</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.ratios?.bloodVolumeFractionT1.toFixed(2)}
                    </span>
                  </div>
                  <label>Shallow oxygenation</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.ratios?.oxygenationT1.toFixed(2)}
                    </span>
                  </div>
                </div>
              )}
              {isPulseWaveAssessment(ass) && (
                <div className={styles.data}>
                  <label>Ejection time</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>{ass.lveti?.value}</span>
                    <span className={styles.unit}>ms</span>
                  </div>
                  <label>Pulsewave Velocity</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.pulseWaveVelocity?.value}
                    </span>
                    <span className={styles.unit}>m/s</span>
                  </div>
                  <label>Hand asymmetry</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.handAsymmetry?.value}
                    </span>
                    <span className={styles.unit}>ms</span>
                  </div>
                  <label>Foot asymmetry</label>
                  <div className={styles.valueItem}>
                    <span className={styles.value}>
                      {ass.footAsymmetry?.value}
                    </span>
                    <span className={styles.unit}>ms</span>
                  </div>
                </div>
              )}

              <div
                className={styles.assessmentInfo}
                data-state={ass.assessmentState}
              >
                <div className={styles.item}>
                  <label>State</label>
                  <span className={styles.state}>
                    {readableState(ass.assessmentState)}
                  </span>
                </div>
                {ass.comment && (
                  <div className={styles.item}>
                    <label>Comment</label>
                    <span className={styles.comment}>{ass.comment}</span>
                  </div>
                )}
                {ass.audit && (
                  <div className={styles.byline}>
                    <label>by</label>
                    <span>
                      <AssessedBy auditInfo={ass.audit} />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
